/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import VueRouter, { Route } from 'vue-router'

import { endpointDefault, getApiUrl, segments } from '@/inc/app.config'
import { logger } from '@/inc/utils'
import { Resource } from '@/inc/types'

export const parseRoute = (to: Route) => {
  const { path, meta, params, query } = to
  const api = meta?.api || getApiUrl(meta?.site)
  let endpoint = endpointDefault
  let resource = path

  if (params?.lang) {
    // Remove lang from path
    resource = resource.replace(new RegExp(`^/${params.lang}`), '')
  }

  if (meta?.endpoint) {
    // Set API endpoint
    ;({ endpoint } = meta)
    // Remove endpoint from resource path
    // Check for a named route params or a registered segment
    /* eslint-disable indent */
    const pattern =
      params.endpoint || segments[endpoint]
        ? `(${endpoint}|${
            Array.isArray(segments[endpoint])
              ? (segments[endpoint] as string[]).join('|')
              : (segments[endpoint] as string)
          })`
        : endpoint
    /* eslint-enable indent */
    resource = resource.replace(new RegExp(`^/${pattern}`), '')
  }

  if (query?.preview === 'true') {
    // Manage preview
    const { page_id: pageId, id: postId, p: postType } = query

    if (pageId) {
      resource = `/${pageId}`
    }

    if (postType && postId) {
      endpoint = postType as string
      resource = `/${postId}`
    }
  }

  resource = resource.replace(/^\//, '')

  return {
    api,
    endpoint,
    resource,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const save = (ctx: any | VueRouter, data: Resource, ssr: boolean) => {
  if (ssr) {
    // Save to server response
    ctx.$resource = data
  } else {
    // Can not commit the store here
    // It's not compatible with VueJS lifecycle: content changed but views/components did not yet…
    // So there are matching error between new content from store and existing (old) components
    // We should use route watcher (App)
    // ctx.app.$store.commit('SET_RESOURCE', data)

    // Save to router object
    ctx.$resource = data
  }
}

export async function fetch(
  to: Route,
  ctx: any | VueRouter,
  ssr = false,
  req?: any
) {
  const config: AxiosRequestConfig = {}
  const { meta, query } = to
  const { EPIC_ENV } = process.env
  const isProd = EPIC_ENV === 'production' || EPIC_ENV === 'pre'

  if (isProd && req?.headers && req.headers['X-Ecache-Disable']) {
    !config.headers && (config.headers = {})
    config.headers['X-Ecache-Disable'] = req.headers['X-Ecache-Disable']
  }

  // No fetch for static routes
  // TODO: what about metadata (title, head, …)
  if (meta?.static) {
    ctx.$resource = {
      content: {},
      languages: {},
    }

    return
  }

  const { api, endpoint, resource } = parseRoute(to)

  if (query?.preview === 'true') {
    // Preview header
    config.headers = {
      'x-preview': true,
    }
  }

  const url = `${api}/${endpoint}/${resource}`

  logger.info('[fetch]', url)

  try {
    const response = (await axios.get(url, config)) as AxiosResponse<Resource>

    // Nested, so what?
    // if (params.nested || meta.nested) {}
    if (isProd && ctx?.getHeaders) {
      const mergedHeaders: Record<string, string> = {
        ...(ctx.getHeaders() || {}),
        'Content-Type': 'text/html',
      }

      if (response.headers['cache-control']) {
        mergedHeaders['Cache-Control'] = response.headers['cache-control']
      } else {
        mergedHeaders['Cache-Control'] = 'no-store'
      }

      ctx.set(mergedHeaders)
    }

    save(ctx, response.data, ssr)
  } catch (error) {
    const { response } = error as AxiosError

    if (response && response.status === 404) {
      save(ctx, response.data, ssr)

      if (ssr) {
        // Add 404 status to the server response
        ctx.statusCode = response.status
        ctx.statusMessage = response.statusText
      }
    } else {
      logger.error('[fetch]', error)
    }
  }
}

/**
 * Fetch resource from API directly
 * without being related on routing.
 */
export function fetchLive(to: Route) {
  const { api, endpoint, resource } = parseRoute(to)
  const url = `${api}/${endpoint}/${resource}`

  logger.info('[fetchLive]', url)

  return axios.get(url)
}
